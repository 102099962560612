

  export default {
    props: ['services'],
    methods: {
      getValue(row, name) {
        const data = row.find(item => item.name == name);
        if (data) {
          return data.hasOwnProperty('value') ? data.value : '';
        }
        return '';
      },
      getArrayValue(row,name,key) {
        const arr = this.getValue(row, name);
        const data = arr.find(item => item.key == key);

        if (data) {
          return data.hasOwnProperty('value') ? data.value : '';
        }
        return '';
      }
    }


  }

