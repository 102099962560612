
  import IconCheck from '~/assets/icons/check.svg?inline';
  export default {
    props: ['activity'],
    components: {
      IconCheck,
    },
    computed: {
      desc() {
        const data = this.activity.excerpt.split("</p>");
        data.pop();
        return data;
      }
    },
    methods: {
      stripTags(html) {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.innerText;
      },
      getValue(row, name) {
        const data = row.find(item => item.name == name);
        if (data) {
          return data.hasOwnProperty('value') ? data.value : '';
        }
        return '';
      },
      getArrayValue(row, name, key) {
        const arr = this.getValue(row, name);
        const data = arr.find(item => item.key == key);

        if (data) {
          return data.hasOwnProperty('value') ? data.value : '';
        }
        return '';
      },
    }
  }

