import { render, staticRenderFns } from "./index.vue?vue&type=template&id=bd9a971c&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hero: require('/base/1146/public_html/components/sections/Hero.vue').default,HomeProducts: require('/base/1146/public_html/components/sections/HomeProducts.vue').default,HomeServices: require('/base/1146/public_html/components/sections/HomeServices.vue').default,MainGoal: require('/base/1146/public_html/components/sections/MainGoal.vue').default,Divider: require('/base/1146/public_html/components/common/Divider.vue').default,HomeActivities: require('/base/1146/public_html/components/sections/HomeActivities.vue').default,Statistics: require('/base/1146/public_html/components/sections/Statistics.vue').default,HomeNews: require('/base/1146/public_html/components/sections/HomeNews.vue').default,Partners: require('/base/1146/public_html/components/sections/Partners.vue').default,UsefulLinks: require('/base/1146/public_html/components/sections/UsefulLinks.vue').default})
