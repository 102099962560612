
  import Partners from '../components/sections/Partners.vue';
  import UsefulLinks from '../components/sections/UsefulLinks.vue';
  export default {
    scrollToTop: true,
    components: {
      UsefulLinks,
      Partners
    },
    data: () => ({
      activity: null,
      home: null,
      loaded: false,
      logos: {
        partners: [],
        links: []
      }
    }),
    created() {



      this.$nextTick(function () {
        this.loaded = true;
      })
    },
    mounted() {
      this.getHome();
      this.getActivities();
    },

    async fetch() {
      this.loading = true;
      try {
        const response = await fetch(this.$store.$globals.getUrl('/api/logos')).then(res => res.json());
        this.logos = response.logos;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    methods: {
      async getHome() {
        try {
          const response = await this.$axios.$get('/page/main-goal');
          if (response.status != 'success' || response.code != 200) {
            console.error('Server error');
            return;
          };
          this.home = response.page;
        } catch (e) {
          console.error(`Error ${e}`);
        }
      },
      async getActivities() {
        try {
          const response = await this.$axios.$get('/page/activities');
          if (response.status != 'success' || response.code != 200) {
            console.error('Server error');
            return;
          };
          this.activity = response.page;
        } catch (e) {
          console.error(`Error ${e}`);
          redirect('/404/')
        }
      },
    }
  }

