import { render, staticRenderFns } from "./MainGoal.vue?vue&type=template&id=8ff94d00&scoped=true&"
import script from "./MainGoal.vue?vue&type=script&lang=js&"
export * from "./MainGoal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ff94d00",
  null
  
)

export default component.exports