
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';
  import en from '~/lang/en.json';
  export default {
    props: ['data'],
    components: {
      IconChevron,
    },

  }

