
  import {
    directive
  } from "vue-awesome-swiper";
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';

  export default {
    components: {
      IconChevron,
    },
    directives: {
      swiper: directive
    },
    data: () => ({
      articles: [],
      newsSwiperOption: {
        slidesPerView: 1,
        spaceBetween: 64,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        // parallax: true,
        // preloadImages: false,
        // lazy: true,
        // lazy: {
        //   loadPrevNext: true,
        // },
        navigation: {
          nextEl: '.swiper-button-next-custom',
          prevEl: '.swiper-button-prev-custom',
          cleanStyles: false
        },
        breakpoints: {
          // when window width is >= 320px
          640: {
            slidesPerView: 2,
            spaceBetween: 64
          }

        }

        // ...
      }
    }),
    created() {
      // this.type = this.$route.params.type;
      this.getArticles();
    },
    methods: {
      async getArticles(page = 1) {
        const currentLocale = this.$nuxt.context.app.i18n.locales.filter((lang) => lang.code === this.$nuxt.context.app.i18n.locale)[0];
        try {
          const response = await this.$axios.$get(`/articles/news/`+currentLocale.code+`?page=${page}`);
          if (response.status != 'success' || response.code != 200) {
            console.error('Server error');
            return;
          };
          this.articles = response.articles.data;
        } catch (e) {
          console.error(`Error ${e}`);
        }
      }
    }
  }

