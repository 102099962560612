
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';

  export default {
    props: ['data'],
    components: {
      IconChevron,

    },
  }

