
  import {
    directive
  } from "vue-awesome-swiper";
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';

  export default {
    components: {
      IconChevron,
    },
    directives: {
      swiper: directive
    },
    data: () => ({
      products: {},

      page: 1,
      loading: false,
      newsSwiperOption: {
        slidesPerView: 1,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        // parallax: true,
        // preloadImages: false,
        // lazy: true,
        // lazy: {
        //   loadPrevNext: true,
        // },
        navigation: {
          nextEl: '.swiper_prod-button-next-custom',
          prevEl: '.swiper_prod-button-prev-custom',
          cleanStyles: false
        },
        breakpoints: {
          // when window width is >= 320px
          640: {
            slidesPerView: 4,
            spaceBetween: 48
          }

        }

        // ...
      }
    }),
    created() {
      // this.type = this.$route.params.type;
      this.getProducts();

    },
    mounted(){

    },
    methods: {
      async getProducts(page = 1) {
        this.loading = true;
        try {
          this.page = page;
          let url = `/api/products?page=${this.page}`;
          const response = await fetch(this.$store.$globals.getUrl(url)).then(res => res.json());
          this.products = response.products;
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
    }
  }

