
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';

  export default {
    components: {
      IconChevron,
    },
    data: function () {
      return {
        isloading: false,
        chartOptions: {
          chart: {
            height: '500px',
            id: 'vuechart-example',
            id: 'chartyear',
            type: 'area',
            background: '#fff',
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#2DB661', '#1D472D', '#F2C94C', ],
          title: {
            text: 'Ağbaş kələm, yay əkini üçün',
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 16,
            floating: false,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: 'Mulish',
              color: '#1D472D'
            },
          },
          // subtitle: {
          //   text: 'Azərbaycan',
          //   offsetY: 40,
          //   align: 'left',
          //   margin: 24,
          //   style: {
          //     fontSize: '16px',
          //     fontWeight: 'semi-bold',
          //     fontFamily: 'Mulish',
          //     color: '#747474'
          //   },
          // },
          yaxis: {
            opposite: true
          },
          xaxis: {
            categories: ['2017', '2018', '2019']

          },
          legend: {
            horizontalAlign: 'left'
          },
          tooltip: {
            x: {
              format: 'dd MMM yyyy'
            }
          },
          fill: {

            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100]
            }
          },
        },
        series: [],
        statisticsAll: {
          titles: [],
        },
        selectedProduct: null,
        selectedYear: new Date().getFullYear(),
        empty: false,
        isLoading: false,
        loading: false,
        observer: null,
        limit: 10,
        search: '',
      }
    },
    watch: {
      selectedProduct(value) {
        this.getStatistic(value);
        console.log(value)
        this.chartOptions.title.text = this.statisticsAll.titles.find(item => item.slug == this.selectedProduct)['title'];

      },
    },
    created() {
      this.getStatisticsAll();
    },

    methods: {
      async getStatisticsAll() {
        const response = await fetch(this.$store.$globals.getUrl('/api/statistics/all')).then(res => res.json());
        console.log(response.statistics);
        try {


          for (let item of response.statistics) {
            if (!this.statisticsAll.titles.find(i => i.title == item.title)) {
              this.statisticsAll.titles.push({
                title: item.title,
                slug: item.slug
              });
            }

          };
          this.selectedProduct = response.statistics[0].slug;
        } catch (e) {

        }


      },
      async getStatistic(name) {
        try {
        this.isloading = true
        const response = await fetch(this.$store.$globals.getUrl(`/api/statistics/${name}`)).then(res => res.json());
        const data = response.statistics.data;
        const dt = data.split(",");
        const years = []
        const prices = []
        for (let i = 0; i < dt.length; i++) {
          const tr = dt[i].split("-");
          const year = tr[0];
          const price = tr[1];
          years.push(year.replace('\r\n', ''))
          prices.push(price)

        }

        this.series = [{
          name: response.statistics.title,
          data: prices
        }]
        this.chartOptions = {
          ...this.chartOptions,
          ...{

            xaxis: {
              categories: years
            }
          }
        };
        this.isloading = false
        } catch (e) {
          this.isloading = false;
          console.error(`Error ${e}`);
        }
      },

      updateChart() {
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions(this.chartOptions);
          this.$refs.chart.updateSeries(this.series)
        }
      }

    },

  }

