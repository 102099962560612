
  import {
    directive
  } from "vue-awesome-swiper";
  import IconChevron from '~/assets/icons/chevron-right.svg?inline';

  export default {
    components: {
      IconChevron,
    },
    directives: {
      swiper: directive
    },
    data() {
      return {
        slides: [],
        swiperOption: {
          effect: 'fade',
          speed: 1500,
          autoplay: {
            delay: 2500,
          },
          loop: true,
          dynamicBullets: true,
          slidesPerView: 1,
          // loopedSlides: 0,
          // loopAdditionalSlides: 0,
          preloadImages: false,
          lazy: true,
          lazy: {
            loadPrevNext: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
          },
          navigation: {
            nextEl: '.swiper-button-next-hero',
            prevEl: '.swiper-button-prev-hero'
          }
          // ...
        }
      }
    },
    created() {
      this.getSlides();
    },
    methods: {
      async getSlides() {
        try {
          const response = await this.$axios.$get(`/slides`);
          if (response.status != 'success' || response.code != 200) {
            console.error('Server error');
            return;
          };
          this.slides = response.slides;
        } catch (e) {
          console.error(`Error ${e}`);
        }
      }
    }

  }

